var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("ID Romaneio"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.id,
                          expression: "filters.id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel" },
                      domProps: { value: _vm.filters.id },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "id", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-1" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("PC ID")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.pc_id,
                          expression: "filters.pc_id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel" },
                      domProps: { value: _vm.filters.pc_id },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "pc_id", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Baixa Em")]),
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Baixa em",
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.date_completed,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "date_completed", $$v)
                          },
                          expression: "filters.date_completed",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Criado Em"),
                      ]),
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Criado em",
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.created_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "created_at", $$v)
                          },
                          expression: "filters.created_at",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Origem")]),
                      _c("v-select", {
                        attrs: {
                          searchable: false,
                          clearable: false,
                          reduce: function (status) {
                            return status.value
                          },
                          options: _vm.optionsOrigin,
                          placeholder: "Origem",
                        },
                        model: {
                          value: _vm.filters.origin,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "origin", $$v)
                          },
                          expression: "filters.origin",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block mt-2",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.loading
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("i", {
                              staticClass:
                                "bi bi-search cursor-pointer cursor mr-1",
                            }),
                            _vm._v(" Buscar "),
                          ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              busy: _vm.loading,
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(total_items)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.items.length > 0
                      ? _c("i", { staticClass: "bi bi-box-fill text-success" })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item.items.length) + " "),
                  ]
                },
              },
              {
                key: "cell(total_value)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(item.total_value)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(purchase_order_status)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.purchaseOrderStatus
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.setStatusName(item.purchaseOrderStatus)
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "cell(step)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-badge",
                      { attrs: { variant: _vm.setOrderStepColor(item) } },
                      [_vm._v(_vm._s(_vm.setOrderStep(item)))]
                    ),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(item.created_at)) + " "),
                  ]
                },
              },
              {
                key: "cell(date_completed)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(item.date_completed)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (row) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "orders-purchases-romaneio-payments-view",
                            params: { uuid: row.item.uuid },
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EyeIcon", size: "16" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(expand)",
                fn: function (row) {
                  return [
                    _c("feather-icon", {
                      staticClass: "cursor-pointer",
                      attrs: { icon: "ChevronDownIcon", size: "16" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.toggleRow(row.item, row.index)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (row) {
                  return [
                    _c("b-table", {
                      attrs: {
                        small: "",
                        items: row.item.items,
                        responsive: "",
                        fields: _vm.itemTableColumns,
                        "show-empty": "",
                        "empty-text": "Nenhum item associado",
                        "head-variant": "dark",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(document_type)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                item.purchaseOrderInstallment.order
                                  .orderDocumentType
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.purchaseOrderInstallment.order
                                              .orderDocumentType.name
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                          {
                            key: "cell(supplier_company_name)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                item.purchaseOrderInstallment.order.supplier
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.purchaseOrderInstallment.order
                                              .supplier.company_name
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                          {
                            key: "cell(purchaseOrderInstallment.date)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDate(
                                        item.purchaseOrderInstallment.date
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(purchaseOrderInstallment.total)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        item.purchaseOrderInstallment.total
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(purchaseOrderInstallment.total_final)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        item.purchaseOrderInstallment
                                          .total_final
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(purchaseOrderInstallment.order.total)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        item.purchaseOrderInstallment.order
                                          .total
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(purchaseOrderInstallment.order.installments)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.purchaseOrderInstallment.order
                                        .installments
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(status_name)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                item.purchaseOrderInstallment.status
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          "background-color: " +
                                          item.purchaseOrderInstallment.status
                                            .background +
                                          "; color: " +
                                          item.purchaseOrderInstallment.status
                                            .color +
                                          ";padding: 2px 5px; border-radius: 5px; display: block; align-items: center; align-content: center;",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.purchaseOrderInstallment
                                                .status.name
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                          {
                            key: "cell(purchaseOrder)",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDate(
                                        item.purchaseOrder.created_at
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }