<template>
  <div>
    <b-card class="mb-1">
      <b-form @submit.prevent="getData()">
        <div class="form-row">
          <div class="col-md-1">
            <div class="form-group">
              <label for="">ID Romaneio</label>
              <input type="tel" v-model="filters.id" class="form-control" />
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label for="">PC ID</label>
              <input type="tel" v-model="filters.pc_id" class="form-control" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="">Baixa Em</label>
              <flat-pickr
                v-model="filters.date_completed"
                class="form-control"
                placeholder="Baixa em"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="">Criado Em</label>
              <flat-pickr
                v-model="filters.created_at"
                class="form-control"
                placeholder="Criado em"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="">Origem</label>
              <v-select
                :searchable="false"
                :clearable="false"
                :reduce="(status) => status.value"
                :options="optionsOrigin"
                v-model="filters.origin"
                placeholder="Origem"
              >
              </v-select>
            </div>
          </div>
          <div class="col-md-2">
            <button type="submit" class="btn btn-primary btn-block mt-2">
              <div v-if="loading">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor mr-1"></i> Buscar
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>
    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template #cell(total_items)="{ item }">
          <i
            v-if="item.items.length > 0"
            class="bi bi-box-fill text-success"
          ></i>
          {{ item.items.length }}
        </template>
        <template #cell(total_value)="{ item }">
          {{ item.total_value | toCurrency }}
        </template>

        <template #cell(purchase_order_status)="{ item }">
          <span v-if="item.purchaseOrderStatus">
            {{ setStatusName(item.purchaseOrderStatus) }}
          </span>
          <span v-else>-</span>
        </template>

        <template #cell(step)="{ item }">
          <b-badge :variant="setOrderStepColor(item)">{{
            setOrderStep(item)
          }}</b-badge>
        </template>
        <template #cell(created_at)="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template #cell(date_completed)="{ item }">
          {{ formatDate(item.date_completed) }}
        </template>
        <template #cell(actions)="row">
          <router-link
            :to="{
              name: 'orders-purchases-romaneio-payments-view',
              params: { uuid: row.item.uuid },
            }"
          >
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
            />
          </router-link>
        </template>
        <template #cell(expand)="row">
          <feather-icon
            icon="ChevronDownIcon"
            size="16"
            class="cursor-pointer"
            @click.stop="toggleRow(row.item, row.index)"
          />
        </template>
        <template #row-details="row">
          <b-table
            small
            :items="row.item.items"
            responsive
            :fields="itemTableColumns"
            show-empty
            empty-text="Nenhum item associado"
            head-variant="dark"
          >
            <template #cell(document_type)="{ item }">
              <span
                v-if="item.purchaseOrderInstallment.order.orderDocumentType"
              >
                {{ item.purchaseOrderInstallment.order.orderDocumentType.name }}
              </span>
              <span v-else>-</span>
            </template>

            <template #cell(supplier_company_name)="{ item }">
              <span v-if="item.purchaseOrderInstallment.order.supplier">
                {{ item.purchaseOrderInstallment.order.supplier.company_name }}
              </span>
              <span v-else>-</span>
            </template>

            <template #cell(purchaseOrderInstallment.date)="{ item }">
              {{ formatDate(item.purchaseOrderInstallment.date) }}
            </template>

            <template #cell(purchaseOrderInstallment.total)="{ item }">
              {{ item.purchaseOrderInstallment.total | toCurrency }}
            </template>

            <template #cell(purchaseOrderInstallment.total_final)="{ item }">
              {{ item.purchaseOrderInstallment.total_final | toCurrency }}
            </template>

            <template #cell(purchaseOrderInstallment.order.total)="{ item }">
              {{ item.purchaseOrderInstallment.order.total | toCurrency }}
            </template>

            <template
              #cell(purchaseOrderInstallment.order.installments)="{ item }"
            >
              {{ item.purchaseOrderInstallment.order.installments }}
            </template>

            <template #cell(status_name)="{ item }">
              <span
                v-if="item.purchaseOrderInstallment.status"
                :style="`background-color: ${item.purchaseOrderInstallment.status.background}; color: ${item.purchaseOrderInstallment.status.color};padding: 2px 5px; border-radius: 5px; display: block; align-items: center; align-content: center;`"
              >
                {{ item.purchaseOrderInstallment.status.name }}
              </span>
              <span v-else>-</span>
            </template>

            <template #cell(purchaseOrder)="{ item }">
              {{ formatDate(item.purchaseOrder.created_at) }}
            </template>
          </b-table>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  BForm,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import vSelect from "vue-select";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BTable,
    BBadge,
    BDropdownDivider,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BDropdown,
    BDropdownItem,
    CustomPaginateTable,
    BForm,
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {
      loading: true,
      userData: JSON.parse(localStorage.getItem("userData")),
      submitedFilter: false,
      filters: {
        origin: undefined,
      },
      perPage: 100,
      currentPage: 1,
      optionsOrigin: [
        {
          label: "Todos",
          value: undefined,
        },
        {
          label: "Dar Baixa",
          value: 4,
        },
        {
          label: "Pagamento",
          value: 3,
        },
      ],
      optionsSteps: [],
      optionsDocumentTypes: [],
      totalRows: 0,
      rowsTable: 0,
      itemTableColumns: [
        {
          key: "purchaseOrderInstallment.id",
          label: "Id",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.id",
          label: "PC ID",
        },
        {
          key: "supplier_company_name",
          label: "Fornecedor",
          class: "text-center",
        },
        {
          key: "status_name",
          label: "Status",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.installment",
          label: "Parcela",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.total_final",
          label: "Total da Parcela",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.total",
          label: "Total do Pedido",
          class: "text-center",
        },
        {
          key: "document_type",
          label: "Tipo do Pedido",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.document_number",
          label: "Num Doc",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.installments",
          label: "Parcelas do Pedido",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.bank.name",
          label: "Banco",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.bank.account_number",
          label: "Conta",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.bank.agency",
          label: "Agencia",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.date",
          label: "Data",
          class: "text-center",
        },
      ],
      tableColumns: [
        {
          key: "id",
          label: "ID",
          class: "text-center",
        },

        {
          key: "user.full_name",
          label: "Criado por",
          class: "text-center",
        },
        { key: "purchase_order_status", label: "Origem" },

        {
          key: "total_value",
          label: "Valor Total",
          class: "text-center",
        },
        {
          key: "date_completed",
          label: "Baixa em",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Criado em",
          class: "text-center",
        },
        {
          key: "total_items",
          label: "Itens",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
        {
          key: "expand",
          label: "",
          class: "text-center",
        },
      ],
      dataItems: [],
      itemsSelecteds: [],
      selectedItems: [],
      selectAllCheckbox: false,
      filters: {},
    };
  },
  methods: {
    setStatusName(item) {
      return item.id === 3 ? "Pagamento" : "Dar Baixa";
    },
    setOrderStep(item) {
      const oldStep = item.oldStepRelation ? item.oldStepRelation.name : "";
      const newStep = item.newStepRelation
        ? item.newStepRelation.name
        : "Pagamento";
      return `${oldStep} >> ${newStep}`;
    },
    setOrderStepColor(item) {
      const newStep = item.new_step;

      if (newStep === 1) {
        return "success";
      } else if (newStep === 2) {
        return "primary";
      } else if (newStep === 3) {
        return "info";
      } else {
        return "warning";
      }
    },
    toggleRow(row, index) {
      this.$set(
        this.dataItems[index],
        "_showDetails",
        !this.dataItems[index]._showDetails
      );
    },
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
    async getData() {
      try {
        this.loading = true;
        const filtersData = {
          limit: this.perPage,
          page: this.currentPage,
          ...this.filters,
        };

        this.$store
          .dispatch("OrderPurchase/listPaymentMovements", filtersData)
          .then((data) => {
            if (data) {
              this.dataItems = data.data;
              this.rowsTable = data.data.length;
              this.totalRows = data.meta.total;
              this.filters.currentPage = data.meta.current_page;
            }
          });
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.getData();

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
};
</script>
